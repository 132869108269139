import React, { useState } from 'react';
import { Tabs, Modal, Card, Row, Col } from 'antd';
import './document.css';

const classData = [
  {
    className: 'Lớp 2',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3/preview',
      //   title: 'Toán lớp 2 - Bài 1',
      //   description: 'Bài học về số học cơ bản',
      //   cover: 'https://drive.google.com/thumbnail?id=1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3', // Đường dẫn tới ảnh preview
      // },
      // {
      //   url: 'https://drive.google.com/file/d/1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3/preview',
      //   title: 'Toán lớp 2 - Bài 2',
      //   description: 'Bài học về hình học cơ bản',
      //   cover: 'https://drive.google.com/thumbnail?id=1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3', // Đường dẫn tới ảnh preview
      // },
    ],
  },
  {
    className: 'Lớp 3',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3/preview',
      //   title: 'Toán lớp 3 - Bài 1',
      //   description: 'Bài học về phân số',
      //   cover: 'https://drive.google.com/thumbnail?id=1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3', // Đường dẫn tới ảnh preview
      // },
    ],
  },
  {
    className: 'Lớp 4',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3/preview',
      //   title: 'Toán lớp 4 - Bài 1',
      //   description: 'Bài học về số thập phân',
      //   cover: 'https://drive.google.com/thumbnail?id=1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3', // Đường dẫn tới ảnh preview
      // },
    ],
  },
  {
    className: 'Lớp 5',
    files: [
      {
        url: 'https://drive.google.com/file/d/1uBtHBI-B9wyVJuNsO-MLpv4A0f8RNGwy/preview',
        title: '',
        description: 'Đề khảo sát đánh giá năng lực học sinh vào lớp 6 - Trường THCS Lê Quý Đôn, Lý Tự Trọng',
        cover: 'https://drive.google.com/thumbnail?id=1uBtHBI-B9wyVJuNsO-MLpv4A0f8RNGwy&sz=w1200-h800',
      },
      // {
      //   url: 'https://drive.google.com/file/d/1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3/preview',
      //   title: '06_Toán 4 lên 5',
      //   description: '06_GIẢI TOÁN LỜI VĂN',
      //   cover: 'https://drive.google.com/thumbnail?id=1v7YSvA6l92_vXmlz8q46D4wNQnmV_hQ3&sz=w1200-h800', 
      // },
    ],
  },
  {
    className: 'Lớp 6',
    files: [
      // {
      //   url: 'https://drive.google.com/file/d/1NzoZvj3w5HRAJjgGMBVLj44o8Cx8Y2sM/preview',
      //   title: '06_Toán 5 lên 6',
      //   description: '06_LUYỆN TẬP CHUNG',
      //   cover: 'https://drive.google.com/thumbnail?id=1NzoZvj3w5HRAJjgGMBVLj44o8Cx8Y2sM&sz=w1200-h800',
      // },
    ],
  },
];

export default function ExamPage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const showModal = (file) => {
    setCurrentFile(file);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentFile(null);
  };

  return (
    <main className='lg:px-6'>
      <Tabs
        defaultActiveKey="1"
        tabPosition={"left"}
        style={{
          height: 400,
        }}
      >
        {classData.map((cls, index) => (
          <Tabs.TabPane tab={cls.className} key={index + 1}>
            <Row gutter={[16, 16]}>
              {cls.files.map((file, fileIndex) => (
                <Col xs={24} sm={12} md={6} key={fileIndex}>
                  <Card
                    hoverable
                    cover={
                      <div className="cover-container">
                        <img alt={`Thumbnail ${fileIndex + 1}`} src={file.cover} className="cover-image" />
                      </div>
                    }
                    style={{ height: '300px' }}
                    onClick={() => showModal(file.url)}
                  >
                    <Card.Meta title={file.title} description={file.description} />
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Modal
        title="File Preview"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        {currentFile && (
          <iframe
            src={currentFile}
            style={{ width: '100%', height: '500px', border: 'none' }}
          />
        )}
      </Modal>
    </main>
  );
}
