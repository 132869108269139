import Rutgon from "../assets/trending/rutgon.webp"
import Thuantien from "../assets/trending/thuantien.jpg"
import Quydong from "../assets/trending/quydong.jpg"
import Dogoc from "../assets/trending/dogoc.webp"
import Bieuthuc from "../assets/trending/bieuthuc.webp"
import Khaosat from "../assets/trending/kháoat.webp"
import Thembot from "../assets/trending/thembot.webp"
export const trending = [
  {
    "id": 696,
    "title": "Toán 4 | Phân số (P3) - 5 CÁCH RÚT GỌN PHÂN SỐ hay nhất",
    "slug": "code-thieu-nhi-battle-tranh-gianh-tra-sua-size-l",
    "image": Rutgon,
    "video": "sgq7BH6WxL8",
    "duration": 1510,
    "yt_view_count": 4147,
    "yt_comment_count": 14,
    "yt_like_count": 281,
    "image_url": "https://i.ytimg.com/vi/sgq7BH6WxL8/maxresdefault.jpg",
    "video_url": "https://youtu.be/SgH-HPnFbcE?si=TuWL9T4ScAiWARqH"
  },
  {
    "id": 760,
    "title": "Toán 4 | Tính bằng cách thuận tiện nhất biểu thức số tự nhiên (P1)",
    "slug": "sinh-vien-it-di-thuc-tap-tai-doanh-nghiep-can-biet-nhung-gi",
    "image": Thuantien,
    "video": "YH-E4Y3EaT4",
    "duration": 2091,
    "yt_view_count": 2881,
    "yt_comment_count": 28,
    "yt_like_count": 147,
    "image_url": "https://i.ytimg.com/vi/YH-E4Y3EaT4/maxresdefault.jpg",
    "video_url": "https://youtu.be/0CrpWfWrqKE?si=XgVyajl83g2GMNEP"
  },
  {
    "id": 456,
    "title": "Toán 4 | Phân số (P4) - 3 CÁCH quy đồng mẫu số",
    "slug": "phuong-phap-hoc-lap-trinh-cua-admin-f8",
    "image": Quydong,
    "video": "DpvYHLUiZpc",
    "duration": 1446,
    "yt_view_count": 2484,
    "yt_comment_count": 29,
    "yt_like_count": 157,
    "image_url": "https://i.ytimg.com/vi/DpvYHLUiZpc/maxresdefault.jpg",
    "video_url": "https://youtu.be/Mjl5bMKnAxY?si=AFyoRUB27O8Q46LT"
  },
  {
    "id": 533,
    "title": "Toán 4 | Ôn thi giữa HK I | Cách đo góc | Hướng dẫn đo góc",
    "slug": "lam-sao-de-co-thu-nhap-cao-va-di-xa-hon-trong-nganh-it",
    "image": Dogoc,
    "video": "oF7isq9IjZM",
    "duration": 1540,
    "yt_view_count": 5113,
    "yt_comment_count": 216,
    "yt_like_count": 268,
    "image_url": "https://i.ytimg.com/vi/oF7isq9IjZM/maxresdefault.jpg",
    "video_url": "https://youtu.be/nZHmeLdhGj4?si=JNpwALzWa9tXiIoX"
  },
  {
    "id": 44,
    "title": "Toán 4 | Tính bằng cách thuận tiện nhất | Biểu thức phân số (P2)",
    "slug": "ban-se-lam-duoc-gi-sau-khoa-hoc",
    "image": Bieuthuc,
    "video": "R6plN3FvzFY",
    "duration": 195,
    "yt_view_count": 8310,
    "yt_comment_count": 107,
    "yt_like_count": 253,
    "image_url": "https://i.ytimg.com/vi/R6plN3FvzFY/maxresdefault.jpg",
    "video_url": "https://youtu.be/E-2BdRq-7TU?si=OQODgMDAyzgyM_el"
  },
  {
    "id": 690,
    "title": "Toán 5 | ĐỀ KHẢO SÁT THÁNG 10-LẦN 1- TRƯỜNG TH ĐOÀN KẾT-Q.LONG BIÊN, 2022-2023",
    "slug": "reactjs-la-gi-tai-sao-nen-hoc-reactjs",
    "image": Khaosat,
    "video": "x0fSBAgBrOQ",
    "duration": 641,
    "yt_view_count": 6215,
    "yt_comment_count": 340,
    "yt_like_count": 275,
    "image_url": "https://i.ytimg.com/vi/x0fSBAgBrOQ/maxresdefault.jpg",
    "video_url": "https://youtu.be/AySaxidvqlw?si=05885ud-Fps0h10l"
  },
  {
    "id": 692,
    "title": "Toán 4 | Phân số (P6) - Tư duy THÊM-BỚT vào tử số và mẫu số ",
    "slug": "javascript-co-the-lam-duoc-gi-gioi-thieu-qua-ve-trang-f8-hoc-lap-trinh-javasc",
    "image": Thembot,
    "video": "0SJE9dYdpps",
    "duration": 473,
    "yt_view_count": 3380,
    "yt_comment_count": 99,
    "yt_like_count": 145,
    "image_url": "https://i.ytimg.com/vi/0SJE9dYdpps/maxresdefault.jpg",
    "video_url": "https://youtu.be/-MAFiTl0WWw?si=-3mvBs1CrTeUFRN0"
  },
  {
    "id": 691,
    "title": "Ôn thi cấp tốc học kì 2 (phần 2) | Toán 4 | 10 chủ đề quan trọng nhất",
    "slug": "lan-tro-lai-nay-f8-se-lam-gi-cho-cac-ban-hoc-lap-trinh-de-di-lam-tai-f8-nao",
    "image": "https://i.ytimg.com/vi/R2L-e3iOy_A/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD4G0Q4yHPiwLQXpL48LYthSI1fiA",
    "video": "ZGmpvhqYSDU",
    "duration": 396,
    "yt_view_count": 1457,
    "yt_comment_count": 42,
    "yt_like_count": 191,
    "image_url": "https://i.ytimg.com/vi/ZGmpvhqYSDU/maxresdefault.jpg",
    "video_url": "https://youtu.be/R2L-e3iOy_A?si=DkH0uRKMAcUVDdzT"
  }
]