import React from "react";
import Logo from '../../assets/logo/logo_math.svg'
import { Link } from 'react-router-dom';
import Facebook from "../../assets/svg/Facebook"
import Zalo from "../../assets/svg/Zalo"
import Youtube from "../../assets/svg/Youtube"
import TikTok from "../../assets/svg/TikTok"

export default function Footer() {
  return (
    <footer className="py-8 lg:pt-16 lg:pb-10 bg-[rgb(24,24,33)] px-4">
      <div className="container mx-auto grid justify-center align-center">
        <section className="grid sm:grid-cols-2 lg:grid-cols-3 lg:justify-items-center">
          <div >
            <div className="flex items-center gap-1 mb-1 mt-[-10px]">
              <img
                className='h-12 w-12'
                src={Logo}
                alt=''
              />
              <h1 className="capitalize font-bold text-white ">
                Toán Thầy Tài
              </h1>
            </div>

            <h3 className="text-tuyn-gray mb-2 text-sm">
              Điện thoại: 096 868 9872
            </h3>
            <h3 className="text-tuyn-gray mb-2 text-sm">
              Email: contact@toanthaytai.edu.vn
            </h3>
            <h3 className="text-tuyn-gray mb-2 text-sm">
              Địa chỉ: Bát Khối, Long Biên, Hà Nội
            </h3>
            {/* <img
              className="pt-3"
              src="https://static.fullstack.edu.vn/static/media/dmca.2593d9ecf1c982e3c3a2.png"
              alt=""
            /> */}
          </div>
          <div>
            <h1 className="capitalize font-bold text-white mb-3">Về Toán Thầy Tài</h1>
            <h3 className="text-tuyn-gray mb-2 text-sm"><Link to='/'>Giới thiệu</Link></h3>
            <div className="flex gap-4 mb-4">
              <Link to='https://www.facebook.com/toanthaytai.vn' target="_blank">
                <Facebook/>
              </Link>
              {/* <Link href='' target="_blank"> */}
                <Zalo/>
              {/* </Link> */}
              <Link to='https://www.youtube.com/@thayduongtientai' target="_blank">
                <Youtube/>
              </Link>
              <Link to='https://www.tiktok.com/@thayduongtientai?_t=8oB5HnDTzRC&_r=1' target="_blank">
                <TikTok/>
              </Link>
            </div>
          </div>
          <div>
            <h1 className="capitalize font-bold text-white mb-3">HỖ TRỢ</h1>
            <h3 className="text-tuyn-gray mb-2 text-sm"><Link to='/'>Liên hệ</Link></h3>
            <h3 className="text-tuyn-gray mb-2 text-sm"><Link to='/'>Bảo mật</Link></h3>
            <h3 className="text-tuyn-gray mb-2 text-sm"><Link to='/'>Điều khoản</Link></h3>
          </div>

        </section>
        <section className="flex mt-5 justify-center">
          <h3 className="text-tuyn-gray mb-2 text-sm">
            © 2022 - 2024 Toán Thầy Tài. All rights reserved.
          </h3>
        </section>
      </div>
    </footer>

  );
}
