import React from 'react';
import { Image } from "antd";

export default function Course_Cal({ lesson }) {
  return (
    <div className='snap-center snap-always'>
      <Image className='rounded-xl' src={lesson.image_url} alt='' />
      <h2 className='font-semibold py-2 text-sm overflow-hidden text-ellipsis whitespace-normal text-center'>{lesson.title}</h2>      {/* <div className='flex gap-3 items-center'>
        <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
          <path d='M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z' />
        </svg>
        <p className='text-tuyn-gray text-sm'>{lesson.students_count}</p>
      </div> */}
    </div>
  );
}
