import React from 'react';
import { Link } from 'react-router-dom';

export default function Learning() {
  return (
    <>
      <div className=' lg:px-8 xl:px-14 pt-7 pb-10'>
        <h1 className='text-3xl font-extrabold mb-3'>Lộ trình học</h1>
        <p className='max-w-[840px] text-gray-700 mb-6 lg:mb-14'>
          Để bắt đầu một cách thuận lợi, bạn nên tập trung vào một lộ trình học.
        </p>
        <section className='flex gap-8 flex-wrap lg:grid-cols-4 md:grid-col-2'>
          <div className='flex justify-between lg:justify-start p-5 gap-6 rounded-xl border-2 w-full lg:w-auto'>
            <div className='w-auto lg:max-w-[320px]'>
              <h2 className='text-2xl font-extrabold mb-4'>Lộ trình học Lớp 2</h2>
              <p className='text-gray-700 mb-4 text-justify'>
                Trong phần này Thầy Tài sẽ chia sẻ cho bạn
                lộ trình để học tốt lớp 2 nhé.
              </p>
              <Link to='details2'>
                <button className='text-white py-2 px-4  w-full text-center sm:w-auto rounded-full font-semibold bg-orange-500'>
                  Xem chi tiết
                </button>
              </Link>
            </div>
            <div className='w-[125px] h-[125px] hidden sm:block rounded-full border-4 border-orange-500 flex-shrink-0'>
              <img
                className='object-cover rounded-full w-full h-full'
                src='https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png'
                alt=''
              />
            </div>
          </div>
          <div className='flex justify-between lg:justify-start p-5 gap-6 rounded-xl border-2 w-full lg:w-auto'>
            <div className='w-auto lg:max-w-[320px]'>
              <h2 className='text-2xl font-extrabold mb-4'>Lộ trình học Lớp 3</h2>
              <p className='text-gray-700 mb-4 text-justify'>
                Trong phần này Thầy Tài sẽ chia sẻ cho bạn
                lộ trình để học tốt lớp 3 nhé.
              </p>
              <Link to='details3'>
                <button className='text-white py-2 px-4  w-full text-center sm:w-auto rounded-full font-semibold bg-orange-500'>
                  Xem chi tiết
                </button>
              </Link>
            </div>
            <div className='w-[125px] h-[125px] hidden sm:block rounded-full border-4 border-orange-500 flex-shrink-0'>
              <img
                className='object-cover rounded-full w-full h-full'
                src='https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png'
                alt=''
              />
            </div>
          </div>
          <div className='flex justify-between lg:justify-start p-5 gap-6 rounded-xl border-2 w-full lg:w-auto'>
            <div className='w-auto lg:max-w-[320px]'>
              <h2 className='text-2xl font-extrabold mb-4'>Lộ trình học Lớp 4</h2>
              <p className='text-gray-700 mb-4 text-justify'>
                Trong phần này Thầy Tài sẽ chia sẻ cho bạn
                lộ trình để học tốt lớp 4 nhé.
              </p>
              <Link to='details4'>
                <button className='text-white py-2 px-4  w-full text-center sm:w-auto rounded-full font-semibold bg-orange-500'>
                  Xem chi tiết
                </button>
              </Link>
            </div>
            <div className='w-[125px] h-[125px] hidden sm:block rounded-full border-4 border-orange-500 flex-shrink-0'>
              <img
                className='object-cover rounded-full w-full h-full'
                src='https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png'
                alt=''
              />
            </div>
          </div>
          <div className='flex justify-between lg:justify-start p-5 gap-6 rounded-xl border-2 w-full lg:w-auto'>
            <div className='w-auto lg:max-w-[320px]'>
              <h2 className='text-2xl font-extrabold mb-4'>Lộ trình học Lớp 5</h2>
              <p className='text-gray-700 mb-4 text-justify'>
                Trong phần này Thầy Tài sẽ chia sẻ cho bạn
                lộ trình để học tốt lớp 5 nhé.
              </p>
              <Link to='details5'>
                <button className='text-white py-2 px-4  w-full text-center sm:w-auto rounded-full font-semibold bg-orange-500'>
                  Xem chi tiết
                </button>
              </Link>
            </div>
            <div className='w-[125px] h-[125px] hidden sm:block rounded-full border-4 border-orange-500 flex-shrink-0'>
              <img
                className='object-cover rounded-full w-full h-full'
                src='https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png'
                alt=''
              />
            </div>
          </div>
          <div className='flex justify-between lg:justify-start p-5 gap-6 rounded-xl border-2 w-full lg:w-auto'>
            <div className='w-auto lg:max-w-[320px]'>
              <h2 className='text-2xl font-extrabold mb-4'>Lộ trình học Lớp 6</h2>
              <p className='text-gray-700 mb-4 text-justify'>
                Trong phần này Thầy Tài sẽ chia sẻ cho bạn
                lộ trình để học tốt lớp 6 nhé.
              </p>
              <Link to='details6'>
                <button className='text-white py-2 px-4  w-full text-center sm:w-auto rounded-full font-semibold bg-orange-500'>
                  Xem chi tiết
                </button>
              </Link>
            </div>
            <div className='w-[125px] h-[125px] hidden sm:block rounded-full border-4 border-orange-500 flex-shrink-0'>
              <img
                className='object-cover rounded-full w-full h-full'
                src='https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png'
                alt=''
              />
            </div>
          </div>

        </section>
        <section className='flex justify-between items-center'>
          <div className='w-10/12 md:max-w-[400px] py-7'>
            <h1 className='text-2xl font-extrabold mb-4'>Tham gia cộng đồng Toán Thầy Tài trên Facebook</h1>
            <p className='text-sm text-justify mb-4'>
              Hàng nghìn người khác đang học lộ trình giống như bạn. Hãy tham gia hỏi đáp, chia sẻ và hỗ trợ nhau trong
              quá trình học nhé.
            </p>

            <a href="https://www.facebook.com/hocgioitoancungthaytai" target="_blank" rel="noopener noreferrer">
              <button className='py-2 px-5 rounded-full border-2 border-black'>Tham gia nhóm</button>
            </a>
          </div>
          <div className='hidden md:block'>
            <img
              className='max-w-[420px] w-full'
              src='https://static.fullstack.edu.vn/static/media/fb-group-cards.4bd525b1b8baf7b1e5a2.png'
              alt=''
            />
          </div>
        </section>
      </div>
    </>
  );
}
