import Login from 'Components/Auth/Login';
import Register from 'Components/Auth/Register';
import AuthPage from 'Pages/Auth';
import HomePage from 'Pages/Home';
import Blog from 'Pages/Home/Blog';
import LearningPage from 'Pages/Home/Learning';
import Learning from 'Pages/Home/Learning/Learning';
import Class2 from 'Pages/Home/Learning/DetailClass2';
import Class3 from 'Pages/Home/Learning/DetailClass3';
import Class4 from 'Pages/Home/Learning/DetailClass4';
import Class5 from 'Pages/Home/Learning/DetailClass5';
import Class6 from 'Pages/Home/Learning/DetailClass6';


import Main from 'Pages/Home/Main';
import NotFound from 'Pages/NotFound';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CoursesPage from 'Pages/Home/Courses';
import DocumentsPage from 'Pages/Home/Documents';
import PostsPage from 'Pages/Posts';
import CreatePostPage from 'Pages/CreatePost';
import Courses from 'Pages/Home/Courses/Courses';
import Details from 'Pages/Home/Courses/Details';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BookMark from 'Pages/Home/BookMark';
import ToastTuyn from 'Components/Toast/ToastTuyn';
import AboutUs from "./Pages/Home/AboutUs"
function App() {
  return (
    <>
      <ToastTuyn />
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='new-post' element={<CreatePostPage />} />
        <Route path='blog/:slug' element={<PostsPage />} />
        <Route path='auth' element={<AuthPage />}>
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
        </Route>
        <Route path='blog/:slug' element={<PostsPage />} />
        <Route path='/' element={<HomePage />}>
          <Route path='' element={<Main />} />
          <Route path='learning' element={<LearningPage />}>
            <Route path='' element={<Learning />} />
            <Route path='details2' element={<Class2 />} />
            <Route path='details3' element={<Class3 />} />
            <Route path='details4' element={<Class4 />} />
            <Route path='details5' element={<Class5 />} />
            <Route path='details6' element={<Class6 />} />
          </Route>
          <Route path='courses' element={<CoursesPage />}>
            <Route path='' element={<Courses />} />
            <Route path=':slug' element={<Details />} />
          </Route>
          <Route path='documents' element={<DocumentsPage />}/>
          </Route>
          <Route path='me/bookmark/posts' element={<BookMark />} />
          <Route path='blog' element={<Blog />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Routes>
    </>
  );
}

export default App;
