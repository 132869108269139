import React from 'react';
import logo from "../../assets/logo/logo_math.svg"
export default function ChildComment() {
  return (
    <div className='flex gap-3'>
      <img
        src={logo}
        alt=''
        className='h-10 w-10 rounded-full object-cover'
      />
      <div className='flex-1'>
        <div className='bg-gray-100 p-4 rounded-lg'>
          <h3 className='text-sm font-bold mb-1'>Thầy Dương Tiến Tài</h3>
          <p className='text-sm'>
            Toán Thầy Tài !
          </p>
        </div>
        {/* <div className='py-2'>
          <span className='text-xs text-orange-600 mr-3 font-medium'>Thích</span>
          <span className='text-xs text-orange-600 mr-3 font-medium'>Trả Lời</span>
          <span className='text-xs mr-3 text-gray-500 font-medium'>16 ngày trước</span>
        </div> */}
      </div>
    </div>
  );
}
