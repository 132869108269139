import Class2 from "../assets/image_course/class2_svg.svg"
import Class3 from "../assets/image_course/class3_svg.svg"
import Class4 from "../assets/image_course/class4_svg.svg"
import Class5 from "../assets/image_course/class5_svg.svg"
import Class6 from "../assets/image_course/class6_svg.svg"
import Calender2 from "../assets/calendar/class2.png"
import Calender3 from "../assets/calendar/class3.png"
import Calender4KN from "../assets/calendar/class4_kn.png"
import Calender4CD from "../assets/calendar/class4_cd.png"
import Calender5KN from "../assets/calendar/class5_kn.png"
import Calender5CD from "../assets/calendar/class5_cd.png"
import Calender6KN from "../assets/calendar/class6_kn.png"
import Calender6CD from "../assets/calendar/class6_cd.png"

export const listCourse = [
  {
    "title": "Khóa học 2024-2025",
    "slug": "class-1",
    "courses": [

      {
        "id": 2,
        "title": "Kiến thức Lớp 2",
        "slug": "html-css",
        "description": "Trong khóa này chúng ta sẽ cùng nhau xây dựng giao diện 2 trang web là The Band & Shopee.",
        "image": "courses/2.png",
        "icon": "courses/2/6200aecea81de.png",
        "video_type": "youtube",
        "video": "R6plN3FvzFY",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 19,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Class2,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/2/6200aecea81de.png",
        "video_url": "",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 3,
        "title": "Kiến thức Lớp 3",
        "slug": "responsive-web-design",
        "description": "Trong khóa này chúng ta sẽ học về cách xây dựng giao diện web responsive với Grid System, tương tự Bootstrap 4.",
        "image": "courses/3.png",
        "icon": "courses/3/6200afe1240bb.png",
        "video_type": "youtube",
        "video": "uz5LIP85J5Y",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 68,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Class3,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/3/6200afe1240bb.png",
        "video_url": "https://www.youtube.com/watch?v=uz5LIP85J5Y",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 1,
        "title": "Kiến thức Lớp 4",
        "slug": "javascript-co-ban",
        "description": "Học Javascript cơ bản phù hợp cho người chưa từng học lập trình. Với hơn 100 bài học và có bài tập thực hành sau mỗi bài học.",
        "image": "courses/1.png",
        "icon": "courses/1/6200ad9d8a2d8.png",
        "video_type": "youtube",
        "video": "0SJE9dYdpps",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 97,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Class4,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/1/6200ad9d8a2d8.png",
        "video_url": "https://www.youtube.com/watch?v=0SJE9dYdpps",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 12,
        "title": "Kiến thức Lớp 5",
        "slug": "javascript-nang-cao",
        "description": "Hiểu sâu hơn về cách Javascript hoạt động, tìm hiểu về IIFE, closure, reference types, this keyword, bind, call, apply, prototype, ...",
        "image": "courses/12.png",
        "icon": "courses/12/6200af2620118.png",
        "video_type": "youtube",
        "video": "MGhw6XliFgo",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 126,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Class5,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/12/6200af2620118.png",
        "video_url": "https://www.youtube.com/watch?v=MGhw6XliFgo",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 14,
        "title": "Kiến thức Lớp 6",
        "slug": "windows-terminal-wsl",
        "description": "Sở hữu một Terminal hiện đại, mạnh mẽ trong tùy biến và học cách làm việc với Ubuntu là một bước quan trọng trên con đường trở thành một Web Developer.",
        "image": "courses/14/624faac11d109.png",
        "icon": "courses/14/624faac2ee23d.png",
        "video_type": "youtube",
        "video": "7ppRSaGT1uw",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 91,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Class6,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/14/624faac2ee23d.png",
        "video_url": "https://www.youtube.com/watch?v=7ppRSaGT1uw",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
    ],
    "image_url": "https://files.fullstack.edu.vn/f8-prod/learning-paths/2/61a0439062b82.png"
  }
]
export const listCalender = [
  {
    "id": 3,
    "title": "Thời khóa biểu năm học 2024-2025",
    "slug": "tkb",
    "courses": [
      {
        "id": 2,
        "title": "Thời khóa biểu Lớp 2",
        "slug": "html-css",
        "description": "Trong khóa này chúng ta sẽ cùng nhau xây dựng giao diện 2 trang web là The Band & Shopee.",
        "image": "courses/2.png",
        "icon": "courses/2/6200aecea81de.png",
        "video_type": "youtube",
        "video": "R6plN3FvzFY",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 120489,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender2,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/2/6200aecea81de.png",
        "video_url": "",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 3,
        "title": "Thời khóa biểu Lớp 3",
        "slug": "responsive-web-design",
        "description": "Trong khóa này chúng ta sẽ học về cách xây dựng giao diện web responsive với Grid System, tương tự Bootstrap 4.",
        "image": "courses/3.png",
        "icon": "courses/3/6200afe1240bb.png",
        "video_type": "youtube",
        "video": "uz5LIP85J5Y",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 24268,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender3,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/3/6200afe1240bb.png",
        "video_url": "https://www.youtube.com/watch?v=uz5LIP85J5Y",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 1,
        "title": "Thời khóa biểu Lớp 4 - Chương trình sách Kết nối",
        "slug": "javascript-co-ban",
        "description": "Học Javascript cơ bản phù hợp cho người chưa từng học lập trình. Với hơn 100 bài học và có bài tập thực hành sau mỗi bài học.",
        "image": "courses/1.png",
        "icon": "courses/1/6200ad9d8a2d8.png",
        "video_type": "youtube",
        "video": "0SJE9dYdpps",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 76927,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender4KN,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/1/6200ad9d8a2d8.png",
        "video_url": "https://www.youtube.com/watch?v=0SJE9dYdpps",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 12,
        "title": "Thời khóa biểu Lớp 4 - Chương trình sách Cánh diều",
        "slug": "javascript-nang-cao",
        "description": "Hiểu sâu hơn về cách Javascript hoạt động, tìm hiểu về IIFE, closure, reference types, this keyword, bind, call, apply, prototype, ...",
        "image": "courses/12.png",
        "icon": "courses/12/6200af2620118.png",
        "video_type": "youtube",
        "video": "MGhw6XliFgo",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 16631,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender4CD,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/12/6200af2620118.png",
        "video_url": "https://www.youtube.com/watch?v=MGhw6XliFgo",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 14,
        "title": "Thời khóa biểu Lớp 5 - Chương trình sách Kết nối",
        "slug": "windows-terminal-wsl",
        "description": "Sở hữu một Terminal hiện đại, mạnh mẽ trong tùy biến và học cách làm việc với Ubuntu là một bước quan trọng trên con đường trở thành một Web Developer.",
        "image": "courses/14/624faac11d109.png",
        "icon": "courses/14/624faac2ee23d.png",
        "video_type": "youtube",
        "video": "7ppRSaGT1uw",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 3921,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender5KN,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/14/624faac2ee23d.png",
        "video_url": "https://www.youtube.com/watch?v=7ppRSaGT1uw",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 15,
        "title": "Thời khóa biểu Lớp 5 - Chương trình sách Cánh diều",
        "slug": "windows-terminal-wsl",
        "description": "Sở hữu một Terminal hiện đại, mạnh mẽ trong tùy biến và học cách làm việc với Ubuntu là một bước quan trọng trên con đường trở thành một Web Developer.",
        "image": "courses/14/624faac11d109.png",
        "icon": "courses/14/624faac2ee23d.png",
        "video_type": "youtube",
        "video": "7ppRSaGT1uw",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 3921,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender5CD,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/14/624faac2ee23d.png",
        "video_url": "https://www.youtube.com/watch?v=7ppRSaGT1uw",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 16,
        "title": "Thời khóa biểu Lớp 6 - Chương trình sách Kết nối",
        "slug": "windows-terminal-wsl",
        "description": "Sở hữu một Terminal hiện đại, mạnh mẽ trong tùy biến và học cách làm việc với Ubuntu là một bước quan trọng trên con đường trở thành một Web Developer.",
        "image": "courses/14/624faac11d109.png",
        "icon": "courses/14/624faac2ee23d.png",
        "video_type": "youtube",
        "video": "7ppRSaGT1uw",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 3921,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender6KN,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/14/624faac2ee23d.png",
        "video_url": "https://www.youtube.com/watch?v=7ppRSaGT1uw",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      },
      {
        "id": 17,
        "title": "Thời khóa biểu Lớp 6 - Chương trình sách Cánh diều",
        "slug": "windows-terminal-wsl",
        "description": "Sở hữu một Terminal hiện đại, mạnh mẽ trong tùy biến và học cách làm việc với Ubuntu là một bước quan trọng trên con đường trở thành một Web Developer.",
        "image": "courses/14/624faac11d109.png",
        "icon": "courses/14/624faac2ee23d.png",
        "video_type": "youtube",
        "video": "7ppRSaGT1uw",
        "old_price": 0,
        "price": 0,
        "pre_order_price": 0,
        "students_count": 3921,
        "is_pro": false,
        "is_selling": false,
        "published_at": "2020-02-10T14:23:13.000000Z",
        "is_registered": false,
        "user_progress": 0,
        "last_completed_at": null,
        "image_url": Calender6CD,
        "icon_url": "https://files.fullstack.edu.vn/f8-prod/courses/14/624faac2ee23d.png",
        "video_url": "https://www.youtube.com/watch?v=7ppRSaGT1uw",
        "is_coming_soon": false,
        "is_pre_order": false,
        "is_published": true,
        "related_courses": []
      }
    ],
    "image_url": "https://files.fullstack.edu.vn/f8-prod/learning-paths/3/61a0439cc779b.png"
  }
]