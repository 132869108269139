import Banner_Fb from "../assets/Facebook.png"
import Banner_Yt from "../assets/Youtube.png"
import Thanhqua from "../assets/thanhqua.jpg"
import Thanhqua2 from "../assets/thanhqua2.jpg"
export const slide = [
  {
    title: 'Toán Thầy Tài trên Facebook',
    description:
      'Để đạt được kết quả tốt trong mọi việc ta cần xác định mục tiêu rõ ràng cho việc đó. ',
    buttonTile: 'Truy nhập Fanpage',
    url: "https://www.facebook.com/toanthaytai.vn",
    thumbnail: Banner_Yt,
    bgFrom: 'rgb(254,33,94)',
    bgTo: 'rgb(255,148,2)',
  },
  {
    title: 'Toán Thầy Tài trên Youtube',
    description:
      'Toán Thầy Tài được nhắc tới ở mọi nơi, ở đâu có những con người yêu thích Toán học, Toán Thầy Tài sẽ ở đó.',
    buttonTile: 'Truy cập kênh',
    url: "https://www.youtube.com/channel/UC5PCQQA_nULiSdVNyf2z9DA",
    thumbnail: Banner_Yt,
    bgFrom: 'rgb(254,33,94)',
    bgTo: 'rgb(0,126,254)',
  },
  // {
  //   title: 'Thành Quả của Học Sinh',
  //   description:
  //     'Để đạt được kết quả tốt trong mọi việc ta cần xác định mục tiêu rõ ràng cho việc đó. ',
  //   buttonTile: 'Xem thành quả',
  //   thumbnail: Thanhqua,
  //   bgFrom: 'rgb(254,33,94)',
  //   bgTo: 'rgb(255,148,2)',
  // },

  // {
  //   title: 'Thành Quả của Học Sinh',
  //   description:
  //     'Để đạt được kết quả tốt trong mọi việc ta cần xác định mục tiêu rõ ràng cho việc đó. ',
  //   buttonTile: 'Xem thành quả',
  //   thumbnail: Thanhqua2,
  //   bgFrom: '#7600FF',
  //   bgTo: '#05B2FF',
  // },
];
