import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, name, title, text, action }) {
  return (
    <Wrapper>
      <ImgBtn className="animate pointer">
        <img className="radius8" src={img} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 font-bold mt-4">{name}</h3>
      <h3 className="font20 font-bold">{title}</h3>
      <p className="font13 text-left text-justify">{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  justify-content: center; 
  align-items: center;
  img {
    width: 80%;
    height: auto; 
    object-fit: cover; 

  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center;    /* Căn giữa theo chiều dọc */
  :hover > img {
    opacity: 0.5;
  }
`;
