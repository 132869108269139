import { AuthApi } from 'Apis/AuthApi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button } from 'antd';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from "../../firebaseConfig";

const schema = yup.object({
  full_name: yup.string().required('Không được để trống'),
  email: yup.string().required('Không được để trống'),
  password: yup.string().required('Không được để trống'),
  retype_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Mật khẩu không khớp')
    .required('Không được để trống'),
});

export default function Register() {
  const [isMenu, setMenu] = useState(false);
  const [isMenuPhone, setMenuPhone] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false);
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = async (data) => {
    if (!isEmailVerified) {
      toast.error("Bạn cần xác minh email trước khi đăng ký");
      return;
    }
    try {
      setLoadingRegister(true);
      await AuthApi.register(data);
      toast.success("Đăng ký thành công");
      setTimeout(() => {
        navigate('/auth/login');
      }, 1000);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoadingRegister(false);
    }
  };

  const sendEmail = async () => {
    const email = getValues('email');
    try {
      setLoadingSendEmail(true);
      await AuthApi.sendOTP({ email });
      toast.success("Đã gửi mã xác thực đến email của bạn");
      setIsOtpSent(true);
    } catch (error) {
      toast.error("Gửi email xác thực thất bại");
    } finally {
      setLoadingSendEmail(false);
    }
  };

  const verifyEmail = async () => {
    const email = getValues('email');
    try {
      setLoadingVerifyEmail(true);
      const response = await AuthApi.confirmOTP({ email, otp });
      console.log("test", response)
      if (response.message === 'OTP code verification successful!') {
        setIsEmailVerified(true);
        toast.success("Email đã được xác minh thành công");
      } else {
        toast.error("Mã OTP không chính xác hoặc đã hết hạn");
      }
    } catch (error) {
      toast.error("Xác thực email thất bại");
    } finally {
      setLoadingVerifyEmail(false);
    }
  };

  const [phone, setPhone] = useState("");
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const sendOTP = async () => {
    try {
      setLoadingSendOTP(true);
      const recaptcha = new RecaptchaVerifier('recaptcha', {
        size: 'invisible',
        callback: (response) => {
        }
      }, auth); const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha);
      setUser(confirmation);
      toast.success("Mã OTP đã được gửi đến số điện thoại của bạn");
    } catch (err) {
      console.log(err);
      toast.error("Gửi OTP thất bại");
    } finally {
      setLoadingSendOTP(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoadingVerifyOTP(true);
      await user.confirm(otp);
      setIsVerified(true);
      toast.success("Số điện thoại đã được xác minh thành công");
    } catch (err) {
      toast.error("Mã OTP không chính xác");
    } finally {
      setLoadingVerifyOTP(false);
    }
  };

  const handlePhoneChange = (phone) => {
    setPhone("+" + phone);
    setValue('email', phone);
  };

  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+84")) {
      if (phone[3] !== '0') {
        return "+840" + phone.slice(3);
      }
    }
    return phone;
  };

  const submitFormPhone = async (data) => {
    if (!isVerified) {
      toast.error("Bạn cần xác minh số điện thoại trước khi đăng ký");
      return;
    }
    try {
      setLoadingRegister(true);
      const full_name = getValues('full_name');
      const password = getValues('password');
      const formattedPhone = formatPhoneNumber(phone);
      let phoneWithoutCountryCode = formattedPhone;
      if (formattedPhone.startsWith("+84")) {
        phoneWithoutCountryCode = formattedPhone.slice(3);
      }
      const data = { full_name, email: phoneWithoutCountryCode, password };
      const result = await AuthApi.register(data);
      toast.success("Đăng ký thành công");
      setTimeout(() => {
        navigate('/auth/login');
      }, 1000);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoadingRegister(false);
    }
  };

  return (
    <>
      <h3 className='text-2xl md:text-3xl font-bold mb-10 text-center'>Đăng ký tài khoản Toán Thầy Tài</h3>
      {!isMenu && !isMenuPhone && (
        <>
          <div
            onClick={() => {
              setMenu(true);
            }}
            className='border-2 rounded-full cursor-pointer py-3 px-6 flex items-center  justify-center gap-3 max-w-[320px] w-full mb-4'
          >
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
                clipRule='evenodd'
              />
            </svg>
            <p className='text-sm font-semibold'>Sử dụng email của bạn</p>
          </div>
          <div
            onClick={() => {
              setMenuPhone(true);
            }}
            className='border-2 rounded-full py-3 px-6 flex items-center cursor-pointer justify-center gap-3 max-w-[320px] w-full mb-4'
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 15a1 1 0 110-2 1 1 0 010 2zm3-3H7V5h6v9z" />
            </svg>
            <p className='text-sm font-semibold'>Sử dụng số điện thoại của bạn</p>
          </div>
        </>
      )}
      {isMenu && (
        <>
          <form onSubmit={handleSubmit(submitForm)} className='flex flex-col items-center w-full'>
            <input
              {...register('full_name')}
              type='text'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Nhập họ tên'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>{errors.full_name?.message}</p>
            <div className="flex items-center gap-4 mb-4 w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200">
              <input
                {...register('email')}
                type='text'
                className='w-full px-4 py-2 rounded-full bg-slate-200 focus:outline-orange-400'
                placeholder='Nhập vào email'
                disabled={loadingSendEmail}
              />
              <Button
                onClick={sendEmail}
                type="primary"
                className="bg-gradient-to-r from-[#F5610A] to-[#FA9502] hover:from-[#FA9502] hover:to-[#F5610A] text-white font-bold"
                disabled={loadingSendEmail}
              >
                {loadingSendEmail ? (
                  <svg className="animate-spin h-5 w-5 text-orange-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                ) : (
                  'Gửi mã OTP'
                )}
              </Button>
            </div>
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>{errors.email?.message}</p>
            {isOtpSent && (
              <>

                <div className="flex items-center gap-4 mb-4 w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400">
                  <input
                    type='text'
                    className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 focus:outline-orange-400'
                    placeholder='Nhập mã OTP'
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    disabled={loadingVerifyEmail}
                  />
                  <Button
                    onClick={verifyEmail}
                    type="primary"
                    className="bg-gradient-to-r from-[#F5610A] to-[#FA9502] hover:from-[#FA9502] hover:to-[#F5610A] text-white font-bold"
                    disabled={loadingVerifyEmail}
                  >
                    {loadingVerifyEmail ? (
                      <svg className="animate-spin h-5 w-5 text-orange-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                    ) : (
                      'Xác thực OTP'
                    )}
                  </Button>
                </div>
              </>
            )}
            <input
              {...register('password')}
              type='password'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Nhập vào mật khẩu'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>{errors.password?.message}</p>
            <input
              {...register('retype_password')}
              type='password'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Xác nhận mật khẩu'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>
              {errors.retype_password?.message}
            </p>
            <button
              type='submit'
              className={`w-full sm:w-8/12 text-center py-2 bg-gradient-to-tr from-[#F5610A] to-[#FA9502] text-white rounded-md font-semibold ${loadingRegister ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loadingRegister}
            >
              {loadingRegister ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
              ) : (
                'Đăng ký'
              )}
            </button>
          </form>
          <div
            className='top-9 left-10 absolute cursor-pointer'
            onClick={() => {
              setMenu(false);
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' className='h-9 w-9' viewBox='0 0 20 20' fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </div>
        </>
      )}
      {isMenuPhone && (
        <>
          <form onSubmit={handleSubmit(submitFormPhone)} className='flex flex-col items-center w-full'>
            <input
              {...register('full_name')}
              type='text'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Nhập họ tên'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>{errors.full_name?.message}</p>

            <input
              {...register('password')}
              type='password'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Nhập vào mật khẩu'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>{errors.password?.message}</p>
            <input
              {...register('retype_password')}
              type='password'
              className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 mb-4 focus:outline-orange-400'
              placeholder='Xác nhận mật khẩu'
              disabled={loadingRegister}
            />
            <p className='sm:ml-[-35%] pb-2 text-sm text-right text-red-500 font-medium'>
              {errors.retype_password?.message}
            </p>
            <div className="flex items-center gap-4 mb-4 w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200">
              <div className="flex-grow">
                <PhoneInput
                  inputStyle={{ width: '100%', border: 'none', backgroundColor: 'transparent' }}
                  {...register('email')}
                  country={'vn'}
                  value={phone}
                  onChange={handlePhoneChange}
                  disabled={loadingSendOTP}
                />
              </div>
              <Button
                onClick={sendOTP}
                type="primary"
                className="bg-gradient-to-r from-[#F5610A] to-[#FA9502] hover:from-[#FA9502] hover:to-[#F5610A] text-white font-bold"
                disabled={loadingSendOTP}
              >
                {loadingSendOTP ? (
                  <svg className="animate-spin h-5 w-5 text-orange-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                ) : (
                  'Gửi mã OTP'
                )}
              </Button>
            </div>
            <div id="recaptcha"></div>
            <div className="flex items-center gap-4 mb-4 w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200">
              <input
                className='w-full sm:w-8/12 px-4 py-2 rounded-full bg-slate-200 focus:outline-orange-400'
                placeholder='Nhập mã OTP'
                onChange={(e) => setOtp(e.target.value)}
                disabled={loadingVerifyOTP}
              />
              <Button
                onClick={verifyOTP}
                type="primary"
                className="bg-gradient-to-r from-[#F5610A] to-[#FA9502] hover:from-[#FA9502] hover:to-[#F5610A] text-white font-bold"
                disabled={loadingVerifyOTP}
              >
                {loadingVerifyOTP ? (
                  <svg className="animate-spin h-5 w-5 text-orange-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                ) : (
                  'Xác thực OTP'
                )}
              </Button>
            </div>
            <button
              type='submit'
              className={`w-full sm:w-8/12 text-center py-2 bg-gradient-to-tr from-[#F5610A] to-[#FA9502] text-white rounded-md font-semibold ${loadingRegister ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loadingRegister}
            >
              {loadingRegister ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
              ) : (
                'Đăng ký'
              )}
            </button>
          </form>
          <div
            className='top-9 left-10 absolute cursor-pointer'
            onClick={() => {
              setMenuPhone(false);
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' className='h-9 w-9' viewBox='0 0 20 20' fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </div>
        </>
      )}
      <p className='pt-5 font-medium'>
        Bạn đã có tài khoản?
        <Link className='pl-3 text-orange-400 font-bold' to='/auth/login'>
          Đăng Nhập
        </Link>
      </p>
    </>
  );
}
