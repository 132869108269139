// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKj2jXElPa3Xx5Nqe42J-nCxdZqIuqz1w",
    authDomain: "math-tai.firebaseapp.com",
    projectId: "math-tai",
    storageBucket: "math-tai.appspot.com",
    messagingSenderId: "261362677246",
    appId: "1:261362677246:web:fa9e813aade9e8dbfcfcaf",
    measurementId: "G-YRLKY6NL7B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
