import React from 'react';
import about_us from "../../../assets/image/about_us.jpg"

export default function AboutUs() {
    return (
        <>
            <div className='flex justify-between pb-5'>
                <h1 className='text-xl font-extrabold'>
                    Giới thiệu về <span className='bg-gradient-to-r from-[#F5610A] to-[#FA9502] text-white px-2 py-1 rounded'>Toán Thầy Tài</span>
                </h1>
            </div>
            <div className="flex flex-col md:flex-row bg-orange-100 p-4 gap-2 rounded-lg mb-5">
                <div className="w-full md:w-1/2">
                    <img src={about_us} alt="About Us" className="w-full bg-white h-auto rounded-lg shadow-lg" />
                </div>
                <div className="w-full md:w-1/2 flex flex-col justify-center">
                    <div className="about-text font-semibold indent-8 text-justify p-6 bg-white rounded-lg shadow-lg">
                        <h3 className="mb-4">Chào mừng quý phụ huynh và các em học sinh đến với website học Toán của thầy Dương Tiến Tài. Website chia sẻ kiến thức, xây dựng nội dung các khóa học online về môn Toán dành cho học sinh tiểu học và phát triển lên THCS.</h3>
                        <h3 className="mb-4">Với hơn 6 năm kinh nghiệm giảng dạy và viết sách, thầy Tài luôn không ngừng học hỏi và tự nâng cao trình độ chuyên môn của mình để mang lại chất lượng giảng dạy tốt nhất. Nhờ vậy, thầy đã xây dựng được một phương pháp giáo dục tiên tiến, phù hợp với đặc điểm tâm lý và năng lực của các em học sinh. Từ đó, đảm bảo cho các em học sinh có thể nắm chắc kiến thức trên lớp và phát triển kỹ năng tư duy, sáng tạo.</h3>
                        <h3 className="mb-4">TOÁN THẦY TÀI đã giúp nhiều học sinh đạt được những tiến bộ vượt bậc trong việc học môn Toán. Việc xây dựng mối quan hệ gắn bó với học sinh và phụ huynh là một trong những ưu tiên hàng đầu của thầy, qua đó giúp thầy hiểu rõ hơn về nhu cầu và khả năng của từng học sinh, từ đó có phương pháp giảng dạy hiệu quả nhất.</h3>
                        <h3>Cuối cùng, TOÁN THẦY TÀI mong muốn các em phát triển tư duy sáng tạo, kỹ năng tự học và sự tự tin để giải quyết các vấn đề trong cuộc sống.</h3>
                    </div>
                </div>
            </div>
        </>
    );
}
