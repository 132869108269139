import React from 'react';
import { useLocation } from 'react-router-dom';

export default function Class2() {
    const location = useLocation();
    return (
        <>
            <main className='pl-8'>
                <section className='max-w-[840px] mb-7 md:mb-14 text-sm md:text-base font-medium md:font-normal'>
                    <h1 className='text-xl md:text-2xl font-extrabold capitalize mb-4'>Lộ trình học lớp 2</h1>

                    <p className='text-justify mb-3'>
                        Dưới đây là các khóa học Thầy Tài đã tạo ra dành cho bất cứ em học sinh nào để học tốt môn Toán
                    </p>
                    <blockquote className='relative text-justify before:w-1 px-3 before:h-full ml-4 before:absolute before:top-0 before:left-0 before:bg-orange-400 before:content-[""]'>
                        <p>Các khóa học có thể chưa đầy đủ, Thầy Tài vẫn đang nỗ lực hoàn thiện trong thời gian sớm nhất.</p>
                    </blockquote>
                </section>
                <section className='max-w-[1140px] text-sm font-medium md:font-normal md:text-base'>
                    <h1 className='text-xl font-bold mb-3 capitalize'>1. Bài toán tổng hiệu</h1>
                    <p className='text-sm text-justify font-medium mb-4'>
                        Toán Tổng hiệu Thầy Tài
                    </p>
                    <div className='border-2 p-3 sm:p-4 rounded-xl sm:flex gap-5 items-start mb-4'>
                        <div className='rounded-xl mb-3 sm:mb-0 sm:w-4/12 lg:w-3/12 flex-shrink-0'>
                            <img className='rounded-xl w-full' src='https://files.fullstack.edu.vn/f8-prod/courses/7.png' alt='' />
                        </div>
                        <div>
                            <h1 className='font-bold capitalize'>Kiến Thức Cơ Bản</h1>
                            <p className='text-sm font-medium md:text-base md:font-normal py-3'>Trong khóa này chúng ta sẽ cùng nhau học về các kiến thức về tổng và hiệu</p>
                            <button className='w-full sm:w-auto text-center text-white bg-orange-500 py-2 px-5 rounded-full font-medium'>Xem khóa học</button>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
